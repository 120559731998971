import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "./GeneralSearch.css";
import { searchListings } from "./GeneralSearch.duck"; // Import your new function
import { useConfiguration } from '../../../context/configurationContext';
import LocationAutocompleteInputImpl from "../../../components/LocationAutocompleteInput/LocationAutocompleteInputImpl"
import { types as sdkTypes } from '../../../util/sdkLoader';
import mapboxgl from 'mapbox-gl'; // Ensure Mapbox GL is imported and available
import { sendInquiry } from "../../ListingPage/ListingPage.duck";
import { Snackbar, Alert } from "@mui/material";



const { LatLng: SDKLatLng, LatLngBounds: SDKLatLngBounds } = sdkTypes;




const GeneralSearch = () => {
  const config = useConfiguration();
  const dispatch = useDispatch();

  // State management for form inputs
  const [address, setAddress] = useState("");
  const [category, setCategory] = useState("");
  const [categoryDisplay, setCategoryDisplay] = useState("");

  const [selectedDate, setSelectedDate] = useState(null);
  const [message, setMessage] = useState("");
  const [initialMessage, setInitialMessage] = useState("");


  const [searchInProgress, setSearchInProgress] = useState(false); // Local loading state
  const [searchResults, setSearchResults] = useState([]); // Local state for listings
  const [addressInput, setAddressInput] = useState({
    search: "",
    predictions: [],
    selectedPlace: null,
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  useEffect(() => {
    const updatedMessage = `Hi I am looking for: ${categoryDisplay || "category"}, on ${
      selectedDate || "a date"
    } in ${addressInput.selectedPlace?.address || "a location"}`;
    setInitialMessage(updatedMessage);
  }, [categoryDisplay, selectedDate, addressInput.selectedPlace]);

  const currentUser = useSelector(state => state.user.currentUser);


  const today = new Date();

  const handleAddressChange = newValue => {
    setAddressInput(newValue);
  };

  const RADIUS_MILES_TO_METERS = 1609.34; // Conversion factor

  /**
   * Calculate a bounding box for a given center and radius
   * @param {number} lat Latitude of the center point
   * @param {number} lng Longitude of the center point
   * @param {number} radius Radius in miles
   * @returns {Object} Bounding box with NE and SW points
   */
  function calculateBoundingBox(lat, lng, radius) {



    const bounds = new mapboxgl.LngLat(lng=lng, lat=lat).toBounds(radius);

    return new SDKLatLngBounds(
      new SDKLatLng(bounds.getNorth(), bounds.getEast()),
      new SDKLatLng(bounds.getSouth(), bounds.getWest())
    );
  }

  function formatAndValidatePhoneNumber(phoneNumber) {
    if (!phoneNumber) {
      console.warn('Phone number is missing');
      return null; // Skip if no phone number
    }

    // Remove all spaces
    let cleanedNumber = phoneNumber.replace(/\D+/g, '');

    // Check if the number starts with +1
    if (cleanedNumber.startsWith('+1')) {
      cleanedNumber = cleanedNumber;
    } else if (cleanedNumber.startsWith('1')) {
      // If it starts with '1', add the '+' prefix
      cleanedNumber = `+${cleanedNumber}`;
    } else {
      // If it doesn't start with '1', prepend '+1'
      cleanedNumber = `+1${cleanedNumber}`;
    }

    // Validate the total length (e.g., +1XXXXXXXXXX -> 12 characters)
    if (cleanedNumber.length !== 12) {
      console.warn('Invalid phone number length:', cleanedNumber);
      return null; // Skip invalid numbers
    }

    return cleanedNumber;
  }

  const fetchProtectedData = async authorId => {
    const response = await fetch('/protectedData', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userId: authorId }),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch protected data: ${response.statusText}`);
    }

    const data = await response.json();
    return data?.data?.phoneNumber;
  };

  const sendTextMessage = async (phone, orderId, inquire) => {
    const response = await fetch('/send-text', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ phone, id: orderId, inquire }),
    });

    if (!response.ok) {
      throw new Error(`Failed to send text: ${response.statusText}`);
    }
  }
  


  const handleSubmit = async e => {
    e.preventDefault();

    if(!currentUser) {
      setSnackbarMessage("You need to log in to proceed.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
      return;    }

  
    try {
      setSearchInProgress(true);
      let successfulMessagesCount = 0;
  
      if (addressInput.selectedPlace && addressInput.selectedPlace.origin && category && selectedDate) {

        const { lat, lng } = addressInput.selectedPlace.origin;
  
        const bounds = calculateBoundingBox(lat, lng, 50000); // Radius in miles
  
        const searchParams = {
          category,
          location: addressInput.selectedPlace.address,
          bounds,
        };
  
        const response = await dispatch(searchListings(searchParams, config));
        setSearchResults(response);

        for (const listing of response.data) {
          if (listing.relationships?.author?.data?.id?.uuid !== currentUser?.id?.uuid) {
            try {
              const messageFull = `${initialMessage}\n\n${message}`;
              const transactionId = await dispatch(sendInquiry(listing, messageFull));
              successfulMessagesCount++
              const authorId = listing.relationships?.author?.data?.id?.uuid

              if (authorId) {
                try {
                  const phoneNumber = await fetchProtectedData(authorId);
                  const formattedPhoneNumber = formatAndValidatePhoneNumber(phoneNumber);
                  if (formattedPhoneNumber) {
                    await sendTextMessage(formattedPhoneNumber, transactionId?.uuid, true);
                  } else {
                    console.warn('Invalid phone number, skipping text message.');
                  }
                } catch (error) {
                  console.error('Error processing text message:', error);
                }
              }
            } catch (error) {
                console.error("Error sending inquiry:", error);
            }
          }
        }
        if(successfulMessagesCount === 0) {
          setSnackbarMessage(`No listings were found for the searching parameters`);
          setSnackbarSeverity("error");
          setOpenSnackbar(true);
        } else {
          setSnackbarMessage(`Inquiries sent successfully for ${successfulMessagesCount} listing(s)!`);
          setSnackbarSeverity("success");
          setOpenSnackbar(true);
        }
        
      } else {
        setSnackbarMessage("Please fill in all fields");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error retrieving listings or sending inquiries:", error);
    } finally {
      setSearchInProgress(false);
    }
};

  return (
    <form onSubmit={handleSubmit} className="general-search-form">
    <h2>Search for Listings</h2>

    {/* Address, Category, and Date in the same row */}
    <div className="first-three-inputs">
        {/* Address Input */}
        <div className="form-group">
            <label htmlFor="address">Address</label>
            <LocationAutocompleteInputImpl
                input={{
                    name: "address",
                    value: addressInput,
                    onChange: handleAddressChange,
                    onFocus: () => {},
                    onBlur: () => {},
                }}
                meta={{
                    valid: true,
                    touched: false,
                }}
                placeholder="Enter address"
                showIcon={false}
            />
        </div>

        {/* Category Select */}
        <div className="form-group">
            <label htmlFor="category">Category</label>
            <select
                id="category"
                value={category}
                onChange={e => {
                    setCategory(e.target.value);
                    setCategoryDisplay(e.target.options[e.target.selectedIndex].text);
                }}
            >
                <option value="">Select a category</option>
                <option value="tutti-helper">Tutti Helper</option>
                <option value="babysitter">Babysitter</option>
                <option value="activities">Activities</option>
                <option value="private-chef">Chefs and Fresh Produce</option>
                <option value="health_beauty">Health & Beauty</option>
                <option value="concierge">Concierge</option>
                <option value="rentals">Rentals</option>
                <option value="photographer">Photography</option>
            </select>
        </div>

        {/* Date Picker */}
        <div className="form-group">
            <label htmlFor="date">Select Date</label>
            <DatePicker
                selected={selectedDate}
                onChange={date => setSelectedDate(date.toISOString().split("T")[0])}
                dateFormat="MMMM d, yyyy"
                placeholderText="Select a date"
                minDate={today}
                id="date"
            />
        </div>
    </div>


    {addressInput.selectedPlace && addressInput.selectedPlace.origin && category && selectedDate &&
      <div className="form-group">
        <p><strong>{initialMessage}</strong></p>
      </div>
    }              
    {/* Message */}
    <div className="form-group message-row">
        <label htmlFor="message">Additional Info</label>
        <textarea
            id="message"
            value={message}
            onChange={e => setMessage(e.target.value)}
            placeholder="Write your message here"
            rows="4"
        ></textarea>
    </div>

    {/* Submit Button */}
    <button type="submit" className="submit-button" disabled={searchInProgress}>
        {searchInProgress ? "Loading..." : "Search"}
    </button>
    <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
    >
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
        </Alert>
    </Snackbar>
  </form>

  );
};

export default GeneralSearch;