// GeneralSearch.duck.js

import { storableError } from '../../../util/errors';
import { addMarketplaceEntities } from '../../../ducks/marketplaceData.duck';
import {
  parseDateFromISO8601,
  getExclusiveEndDate,
  addTime,
  subtractTime,
  daysBetween,
  getStartOf,
} from '../../../util/dates'

// Action types
export const SEARCH_LISTINGS_REQUEST = 'app/GeneralSearch/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/GeneralSearch/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/GeneralSearch/SEARCH_LISTINGS_ERROR';

// Reducer
const initialState = {
  searchInProgress: false,
  searchListingsError: null,
  searchResults: [],
};

const generalSearchReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchInProgress: true,
        searchListingsError: null,
      };

    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        searchInProgress: false,
        searchResults: payload.data,
      };

    case SEARCH_LISTINGS_ERROR:
      console.error(payload);
      return {
        ...state,
        searchInProgress: false,
        searchListingsError: payload,
      };

    default:
      return state;
  }
};

export default generalSearchReducer;

// Action creators
export const searchListingsRequest = () => ({
  type: SEARCH_LISTINGS_REQUEST,
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const datesSearchParams = (datesParam, config) => {
  const searchTZ = 'Etc/UTC';
  const datesFilter = config.search.defaultFilters.find(f => f.key === 'dates');
  const values = datesParam ? datesParam.split(',') : [];
  const hasValues = datesFilter && datesParam && values.length === 2;
  const { dateRangeMode, availability } = datesFilter || {};
  const isNightlyMode = dateRangeMode === 'night';
  const isEntireRangeAvailable = availability === 'time-full';

  // SearchPage need to use a single time zone but listings can have different time zones
  // We need to expand/prolong the time window (start & end) to cover other time zones too.
  //
  // NOTE: you might want to consider changing UI so that
  //   1) location is always asked first before date range
  //   2) use some 3rd party service to convert location to time zone (IANA tz name)
  //   3) Make exact dates filtering against that specific time zone
  //   This setup would be better for dates filter,
  //   but it enforces a UX where location is always asked first and therefore configurability
  const getProlongedStart = date => subtractTime(date, 14, 'hours', searchTZ);
  const getProlongedEnd = date => addTime(date, 12, 'hours', searchTZ);

  const startDate = hasValues ? parseDateFromISO8601(values[0], searchTZ) : null;
  const endRaw = hasValues ? parseDateFromISO8601(values[1], searchTZ) : null;
  const endDate =
    hasValues && isNightlyMode
      ? endRaw
      : hasValues
      ? getExclusiveEndDate(endRaw, searchTZ)
      : null;

  const today = getStartOf(new Date(), 'day', searchTZ);
  const possibleStartDate = subtractTime(today, 14, 'hours', searchTZ);
  const hasValidDates =
    hasValues &&
    startDate.getTime() >= possibleStartDate.getTime() &&
    startDate.getTime() <= endDate.getTime();

  const dayCount = isEntireRangeAvailable ? daysBetween(startDate, endDate) : 1;
  const day = 1440;
  const hour = 60;
  // When entire range is required to be available, we count minutes of included date range,
  // but there's a need to subtract one hour due to possibility of daylight saving time.
  // If partial range is needed, then we just make sure that the shortest time unit supported
  // is available within the range.
  // You might want to customize this to match with your time units (e.g. day: 1440 - 60)
  const minDuration = isEntireRangeAvailable ? dayCount * day - hour : hour;
  return hasValidDates
    ? {
        start: getProlongedStart(startDate),
        end: getProlongedEnd(endDate),
        // Availability can be time-full or time-partial.
        // However, due to prolonged time window, we need to use time-partial.
        availability: 'time-partial',
        // minDuration uses minutes
        minDuration,
      }
    : {};
};

// Thunk function for searching listings
export const searchListings = (searchParams, config) => async (dispatch, getState, sdk) => {
  const {category, date, bounds} = searchParams

  dispatch(searchListingsRequest());
  const datesMaybe = datesSearchParams(date + ',' + date, config);


  const params = {
    include: ['author'],
    'fields.listing': ['id', 'title', 'geolocation', 'price', 'publicData.categories', 'publicData.transactionProcessAlias',
  ],
    pub_category: category,
    bounds: bounds,
  };

  try {
    const response = await sdk.listings.query(params);
    dispatch(addMarketplaceEntities(response));
    dispatch(searchListingsSuccess(response));
    return response.data;
  } catch (error) {
    dispatch(searchListingsError(storableError(error)));
    throw error;
  }
};
